import {isDeveloperMode} from "../utils/utils";

const headerMissionsItem = document.querySelector('#header-item-missions');
const headerTournamentsItem = document.querySelector('#header-item-tournaments');

if (headerMissionsItem && !isDeveloperMode()) {
    headerMissionsItem.remove();
}

if (headerTournamentsItem && !isDeveloperMode()) {
    headerTournamentsItem.remove();
}